<template>
  <div v-if="!!message">
    <v-alert :dismissible="true" type="error">
      <v-row align="center" justify="center">
        <v-col sm="6">
          <v-card-text>
            <span v-html="message" />
          </v-card-text>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import {
  dispatchWarningBanner,
  mapStateWarningBanner,
} from '../store/warningBanner.store'

export default defineComponent({
  computed: {
    ...mapStateWarningBanner({
      message: 'message',
    }),
  },
  async mounted() {
    await dispatchWarningBanner('loadWarningBanner')
  },
})
</script>
<style lang="scss" scoped></style>
