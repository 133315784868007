import axios, { type AxiosResponse } from 'axios'

import { type NotificationsState } from '@/pages/notifications/notifications.store'

import { type TranslatableText } from '../../../types/TranslatableText.type'

export type DeeplinkTarget =
  | 'home'
  | 'showMan'
  | 'show'
  | 'program'
  | 'sponsors'
  | 'practicalInfo'
  | 'map'
  | 'notifications'
  | 'webview'
  | 'extern'
  | 'gallery'
  | 'showmen'
  | 'practicalInfos'

export type NotificationContent = {
  _id?: string
  dateToSend: string
  titles: TranslatableText
  contents: TranslatableText
  author?: string
  deeplinkBase: DeeplinkTarget
  deeplinkParam: string
  creationDate: string
  sent: boolean
  sentDate?: string
  topicId?: string
  sendToDevTopic?: boolean
}

export const NotificationsService = {
  async getAll(): Promise<NotificationContent[]> {
    const { data } = await axios.get('/api/notif')
    return data
  },

  async deleteNotification(id: string): Promise<void> {
    await axios.delete('/api/notif/' + id)
  },

  async addNotification(
    notification: NotificationContent,
  ): Promise<NotificationContent> {
    const { data } = await axios.post('/api/notif', notification)
    return data
  },

  async addNotificationNow(
    notification: NotificationContent,
  ): Promise<NotificationContent> {
    const { data } = await axios.post('/api/notif/fb', notification)
    return data
  },

  async sendOnDevice(
    notification: NotificationContent,
    token: string,
  ): Promise<void> {
    await axios.post(`/api/notif/fb/${token}`, notification)
  },

  async forceGrabDevice(token: string): Promise<void> {
    await axios.post(`/api/notif/forcegrab/${token}`)
  },

  async deleteAll(): Promise<void> {
    await axios.post(`/api/notif/delete_all`)
  },

  async uploadFile(
    file: string | ArrayBuffer | null,
  ): Promise<AxiosResponse<string>> {
    return await axios.post<string>('/api/notif/batch/importXls', {
      file,
    })
  },
  async getImportResult(batchId: string): Promise<
    AxiosResponse<{
      successes: { key: string; content: Record<string, string> }[]
      errors: { key: string; content: Record<string, string> }[]
      toProcess: { key: string; content: Record<string, string> }[]
    }>
  > {
    return await axios.get<
      Exclude<NotificationsState['importFeedback'], undefined>
    >(`/api/notif/batch/importResult/${batchId}`)
  },
}
