import { type RouteConfig } from 'vue-router'

import { dispatchNotifications } from '@/pages/notifications/notifications.store'

import { dispatchApplicationCfg } from '../applicationCfg/store/applicationCfg.store'

export const NotificationsRouter: RouteConfig[] = [
  {
    path: '/notifications',
    component: async () => import('./Notifications.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await Promise.all([dispatchApplicationCfg('loadApplicationCfg')])
      next()
    },
  },
  {
    path: '/notifications/import',
    component: async () => import('./NotificationsImport.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await Promise.all([
        dispatchNotifications('resetImport'),
        dispatchNotifications('list'),
      ])
      next()
    },
  },
]
