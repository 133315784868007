import { ErrorService } from '@/shared/service/errorService'

import { i18n } from '../../i18n/i18n'
import { PAGE_MODE, type PageMode } from '../../shared/constants'
import { SnackbarService } from '../../shared/snackbar/snackbar.service'
import { createStore, type GettersObj } from '../../utils/createStore'
import { NotificationsTopicsService } from '../notificationsTopics/notificationsTopics.service'
import {
  type NotificationContent,
  NotificationsService,
} from './notifications.service'

async function handleCall(
  commit: (mutation: 'LOAD' | 'SET_VIEW') => void,
  dispatch: (action: 'list') => Promise<void>,
  message: string,
  call: () => Promise<void>,
): Promise<void> {
  commit('LOAD')
  try {
    await call()
    await dispatch('list')
    SnackbarService.info(message)
  } catch (e) {
    ErrorService.handleError(e)
  } finally {
    commit('SET_VIEW')
  }
}

export type GettersNotifications = GettersObj<typeof mapGettersNotifications>

export class NotificationsState {
  mode: PageMode | 'add_admin' | 'delete_all' = PAGE_MODE.VIEW
  notifications: NotificationContent[] = []
  currentNotification: NotificationContent | null = null
  loading = false
  importBatchId = ''
  importFeedback?: {
    successes: { key: string; content: Record<string, string> }[]
    errors: { key: string; content: Record<string, string> }[]
    toProcess: { key: string; content: Record<string, string> }[]
  }
}

export const {
  notifications,
  commit: commitNotifications,
  dispatch: dispatchNotifications,
  mapGetters: mapGettersNotifications,
  mapState: mapStateNotifications,
  useGetter: useGetterNotifications,
  useState: useStateNotifications,
} = createStore({
  namespaced: true,
  initState: new NotificationsState(),
  moduleName: 'notifications',
  mutations: {
    LOAD(state) {
      state.loading = true
    },

    SET_DELETING(state, payload: NotificationContent) {
      state.mode = PAGE_MODE.DELETE
      state.currentNotification = { ...payload }
    },

    SET_VIEW(state) {
      state.mode = PAGE_MODE.VIEW
      state.currentNotification = null
      state.loading = false
    },

    UNLOAD(state) {
      state.loading = false
    },

    SET_NOTIFICATIONS(state, notifications: NotificationContent[]) {
      state.notifications = notifications
      state.loading = false
    },

    DELETE_NOTIFICATION(state) {
      state.notifications = state.notifications.filter(
        (n) => n._id !== state.currentNotification?._id,
      )
    },

    SET_ADDING(state) {
      state.mode = PAGE_MODE.ADD
    },

    SET_EDIT(state, notification: NotificationContent) {
      state.mode = PAGE_MODE.EDIT
      state.currentNotification = { ...notification }
    },

    SET_ADDING_ADMIN(state) {
      state.mode = 'add_admin'
    },

    SET_DELETE_ALL(state) {
      state.mode = 'delete_all'
    },
    SET_IMPORT_FEEDBACK(
      state,
      importResult: NotificationsState['importFeedback'],
    ) {
      state.importFeedback = importResult
    },

    SET_IMPORT_BATCH_ID(state, importBatchId: string) {
      state.importBatchId = importBatchId
    },
  },
  actions: {
    async list({ commit }) {
      try {
        commit('LOAD')
        const notifications = await NotificationsService.getAll()
        commit('SET_NOTIFICATIONS', notifications)
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async delete({ commit, state, dispatch }) {
      await handleCall(
        commit,
        dispatch,
        i18n.tc('NOTIFICATIONS.SNACK_BAR.NOTIF_DELETE'),
        async () => {
          if (!state.currentNotification?._id) return
          await NotificationsService.deleteNotification(
            state.currentNotification._id,
          )
          commit('DELETE_NOTIFICATION')
        },
      )
    },

    async addNotification(
      { commit, state, dispatch },
      payload: NotificationContent,
    ) {
      commit('LOAD')
      try {
        payload.sendToDevTopic = state.mode === 'add_admin'

        await NotificationsService.addNotification(payload)
        const message = i18n.tc('NOTIFICATIONS.SNACK_BAR.NOTIF_SENT')

        SnackbarService.info(message)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        await dispatch('list')
        commit('SET_VIEW')
      }
    },

    async addNotificationNow(
      { commit, state, dispatch },
      payload: NotificationContent,
    ) {
      commit('LOAD')
      try {
        payload.sendToDevTopic = state.mode === 'add_admin'
        await NotificationsService.addNotificationNow(payload)
        const message = i18n.tc('NOTIFICATIONS.SNACK_BAR.NOTIF_SENT')

        SnackbarService.info(message)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        await dispatch('list')
        commit('SET_VIEW')
      }
    },

    async sendOnDevice(
      { commit, dispatch },
      payload: { notification: NotificationContent; token: string },
    ) {
      await handleCall(
        commit,
        dispatch,
        i18n.tc('NOTIFICATIONS.SNACK_BAR.SENT_ON_DEVICE'),
        async () => {
          await NotificationsService.sendOnDevice(
            payload.notification,
            payload.token,
          )
        },
      )
    },

    async forceGrabDevice({ commit, dispatch }, payload: { token: string }) {
      await handleCall(
        commit,
        dispatch,
        i18n.tc('NOTIFICATIONS.SNACK_BAR.SENT_ON_DEVICE'),
        async () => {
          await NotificationsService.forceGrabDevice(payload.token)
        },
      )
    },

    async loadTopics({ commit }) {
      commit('LOAD')
      const notifTopics = await NotificationsTopicsService.getAll()
      commit('SET_TOPICS', notifTopics)
    },

    async deleteAll({ commit, dispatch }) {
      await handleCall(
        commit,
        dispatch,
        i18n.tc('NOTIFICATIONS.SNACK_BAR.DELETE_ALL'),
        async () => {
          await NotificationsService.deleteAll()
        },
      )
    },

    async saveFile({ commit, dispatch }, file: File) {
      commit('LOAD')
      try {
        const x64File = await new Promise<string | ArrayBuffer | null>(
          (resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (): void => {
              resolve(reader.result)
            }
            reader.onerror = (error): void => {
              console.error(error)
              reject(
                error instanceof Error
                  ? error
                  : new Error('Error reading file'),
              )
            }
          },
        )
        const results = await NotificationsService.uploadFile(x64File)
        commit('SET_IMPORT_BATCH_ID', results.data)
        await dispatch('updateImportList', results.data)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async updateImportList({ commit, dispatch }, batchId) {
      const results = await NotificationsService.getImportResult(batchId)
      commit('SET_IMPORT_FEEDBACK', results.data)
      if (results.data.toProcess && results.data.toProcess.length > 0) {
        setTimeout(async () => {
          await dispatch('updateImportList', batchId)
        }, 7000)
      } else {
        commit('UNLOAD')
      }
    },

    resetImport({ commit }) {
      commit('SET_IMPORT_FEEDBACK', {
        successes: [],
        errors: [],
        toProcess: [],
      })
      commit('SET_IMPORT_BATCH_ID', '')
    },
  },
  getters: {
    getNotifList: (state) => {
      return state.notifications
    },
  },
})
